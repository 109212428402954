const publicIp = require('public-ip');

const LEAD_DF_UID_FORM_FIELD_NAME = 'lead[df_uid]';

export default class LeadManager {
    /**
     * Converts an object into an object with the lead data
     *
     * @param {FormData} data
     *
     * @return {object}
     */
    static convertFormDataIntoLeadcentreFormat(data) {
        const jsonObject = {};

        jsonObject['chk'] = {};

        data.forEach((value, key) => {
            // treat chk data
            if (key.startsWith('chk')) {
                const id = key.replace('chk[', '').replace(']', '');

                jsonObject['chk'][id] = value;

                return;
            }

            jsonObject[key] = value;
        });

        return {lead : jsonObject};
    }

    /**
     * Converts an object into an object with the lead data
     *
     * @param {object} data
     *
     * @return {object}
     */
    static convertObjectIntoLeadcentreFormat(data) {
        const jsonObject = {};

        jsonObject['chk'] = {};

        for (let key in data) {
            const value = data[key];

            // treat chk data
            if (key.startsWith('chk')) {
                const id = key.replace('chk[', '').replace(']', '');

                jsonObject['chk'][id] = value;

                continue;
            }

            jsonObject[key] = value;
        }

        return {lead : jsonObject};
    }

    /**
     * Add default attributes to lead data
     *
     * @param {object} lead
     * @param {Array} campaigns
     *
     * @return {FormData}
     */
    static async addDefaultAttributesToLead(lead, campaigns) {
        let adcOptionalConsentQuestions = {};

        for (let campaignId in lead.lead.chk) {
            if (typeof lead.lead.chk[campaignId] !== 'string' || lead.lead.chk[campaignId].toLowerCase() !== 'yes') {
                continue;
            }

            // comparison can't be done with === because one type is string and the other is int
            let campaign = campaigns.find(temp => {
                return temp.id == campaignId;
            });

            if (campaign.consent.required) {
                lead.lead.adc_required_consent_question = campaign.consent.text;
                continue;
            }

            const key = campaign.consent.externalId;

            if (typeof adcOptionalConsentQuestions[key] !== 'object') {
                adcOptionalConsentQuestions[key] = {campaigns : []};
            }

            adcOptionalConsentQuestions[key].text = campaign.consent.text;
            adcOptionalConsentQuestions[key].campaigns.push(campaignId);
            lead.lead.adc_optional_consent_questions = JSON.stringify(adcOptionalConsentQuestions);
        }

        lead.lead.user_agent = navigator.userAgent;

        // calculate ip
        try {
            lead.lead.ipaddress = await publicIp.v4();
        } catch(e) {
            console.error('IP could not be retrieved IP v4.');
        }

        if (!lead.lead.ipaddress) {
            try {
                lead.lead.ipaddress = await publicIp.v6();
            } catch(e) {
                console.error('IP could not be retrieved IP v6.');
            }
        }

        // set the full url of the landing page
        lead.lead.full_url = document.location.href;

        // if is iframe, change and set the referrer
        if (window.location !== window.parent.location) {
            lead.lead.full_url   = document.referrer;
            lead.lead.iframe_url = document.location.href;
        }

        if (typeof lead.lead[LEAD_DF_UID_FORM_FIELD_NAME] === 'string') {
            lead.lead.df_uid = lead.lead[LEAD_DF_UID_FORM_FIELD_NAME];
        }

        return lead;
    }

    /**
     * Check if lead data has Required terms accepted
     *
     * @param leadData
     *
     * @returns {boolean}
     */
    static checkTermsAccepted(leadData) {
        for (let campaignId in leadData.lead.chk) {
            if (!leadData.lead.chk.hasOwnProperty(campaignId)) {
                continue;
            }

            if (typeof leadData.lead.chk[campaignId] === 'string' &&
                leadData.lead.chk[campaignId].toLowerCase() === 'yes'
            ) {
                return true;
            }
        }

        console.error('Lead can not be submitted because campaigns consents were not accepted.');

        return false;
    }

    /**
     * Check if lead data has Optional terms accepted
     *
     * @returns {boolean}
     *
     * @param campaigns
     */
    static checkOptionalTermsAccepted(campaigns) {
        for (let campaign in campaigns) {
            if (!campaigns.hasOwnProperty(campaign)) {
                continue;
            }

            if(campaigns[campaign].consent.required === false && campaigns[campaign].selected === true){
                return true;
            }
        }

        return false;
    }

    /**
     * Check if form data has all necessary fields for applying submission
     *
     * @param lead
     * @param necessaryFieldsForSubmission
     *
     * @returns {boolean}
     */
    static checkNecessaryFieldsForSubmission(lead, necessaryFieldsForSubmission) {
        let necessaryFieldsForSubmissionCount = necessaryFieldsForSubmission.length;

        // verify if all necessary fields for applying submission are present on form data
        for (let i = 0; i < necessaryFieldsForSubmissionCount; ++i) {
            if (!lead.hasOwnProperty(necessaryFieldsForSubmission[i])) {
                console.log('Form data does not have necessary fields to apply submission: ' + necessaryFieldsForSubmission[i], lead);

                return false;
            }
        }

        return true;
    }
}
