// import polyfills
import '@babel/polyfill';
import 'formdata-polyfill';

// import dependencies
import Vue           from 'vue';
import AdcConsentApp from './adc-consent-app.vue';
import Consent       from './components/consent';
import store         from './store';


import translations  from './translations';

const ADC_CONSENT_APP_ID           = 'adc-consent-app';
const DEFAULT_CONSENT_BUTTON_CLASS = 'adc-consent-button';
const DEFAULT_LANGUAGE             = 'en';
const DEFAULT_THEME                = 'modal';
const DEFAULT_SOURCE_DOMAIN        = 'adc-lc.eu';

const THEME_SMARKIO               = 'smarkio';
const THEME_INJECTED              = 'injected';
const SMARKIO_FROM_THEM_CLASS     = 'from-them';
const ADC_SMARKIO_FROM_THEM_CLASS = 'adc-consent-smarkio-from-them';

class AdcConsent {
    /**
     * AdcConsent constructor
     */
    constructor(options) {
        this.applyOptions(options);
    }

    /**
     * Apply options
     */
    applyOptions({
        className = DEFAULT_CONSENT_BUTTON_CLASS,
        language = DEFAULT_LANGUAGE,
        theme = DEFAULT_THEME,
        submitLead = false,
        source,
        modal,
        check,
        events,
        filters,
        necessaryFieldsForSubmission,
        elementIdToInject
    }) {
        if (!modal) {
            modal = {};
        }

        if (!check) {
            check = {};
        }

        if (!events) {
            events = {};
        }

        if (!filters) {
            filters = {};
        }

        const {token, pageId, domain} = source;
        const {open = false, strikePartialPartners = true} = modal;
        const {selectAll = false}     = check;

        const {
            onSubmit       = () => true,
            preLeadSubmit  = () => true,
            postLeadSubmit = () => true,
            preModal       = () => true,
            postAccept     = () => true,
            postReject     = () => true
        } = events;

        const {
            treatFormData = data => data
        } = filters;

        this.className = className;
        this.language  = language.toLowerCase();
        this.theme     = theme;

        this.submitLead                   = submitLead;
        this.leadcentreAPIToken           = token;
        this.sourcePageId                 = pageId;
        this.sourceDomain                 = domain ? domain : DEFAULT_SOURCE_DOMAIN;
        this.necessaryFieldsForSubmission = necessaryFieldsForSubmission instanceof Array
            ? necessaryFieldsForSubmission
            : [];
        this.elementIdToInject            = elementIdToInject;

        this.modalOpen                  = open;
        this.modalStrikePartialPartners = strikePartialPartners;

        this.checkSelectAll = selectAll;

        this.onSubmitCallback       = onSubmit;
        this.preLeadSubmitCallback  = preLeadSubmit;
        this.postLeadSubmitCallback = postLeadSubmit;
        this.preModalCallback       = preModal;
        this.postAcceptCallback     = postAccept;
        this.postRejectCallback     = postReject;

        this.treatFormDataFilter = treatFormData;

        this.injectConsent();
    }

    /**
     * Look for buttons with the given className and wrap them with the necessary components
     */
    injectConsent() {
        let button = document.querySelector(`.${this.className}`);

        if (!button) {
            console.error(`ADC-CONSENT: No element was found with class '${this.className}'`);

            return;
        }

        if (this.theme === THEME_SMARKIO) {
            this.setupSmarkio(button);
        }

        let wrapper = document.createElement('adc-consent-app'),
            consent = document.createElement('consent');

        wrapper.id = ADC_CONSENT_APP_ID;

        consent.setAttribute('button-class', this.className);
        consent.setAttribute('theme', this.theme);
        consent.setAttribute(':modal-open', this.modalOpen);
        consent.setAttribute(':modal-strike-partial-partners', this.modalStrikePartialPartners);
        consent.setAttribute(':check-select-all', this.checkSelectAll);
        consent.setAttribute(':submit-lead', this.submitLead);
        consent.setAttribute('leadcentre-token', this.leadcentreAPIToken);
        consent.setAttribute('source-page-id', this.sourcePageId);
        consent.setAttribute('source-domain', this.sourceDomain);

        if(this.theme === THEME_INJECTED) {
            let elementToInject = document.getElementById(this.elementIdToInject);

            if(elementToInject){
                elementToInject.appendChild(wrapper);
                wrapper.appendChild(consent);

                this.initializeApp();

                return;
            }

            console.warn(`Provided element id (${this.elementIdToInject}) to inject consent text was not found`);
        }

        if (button.parentNode) {
            button.parentNode.insertBefore(wrapper, button);
            wrapper.appendChild(consent);
            consent.appendChild(button);

            this.initializeApp();
        }
    }

    /**
     * Add special class to smarkio to correct user experience
     *
     * @param button
     */
    setupSmarkio(button) {
        if (button.parentNode.className === SMARKIO_FROM_THEM_CLASS) {
            button.parentNode.classList.add(ADC_SMARKIO_FROM_THEM_CLASS);
        }
    }

    /**
     * Destroy app to allow another instance to be created with new options
     */
    redefineConsent(newOptions) {
        document.getElementById(ADC_CONSENT_APP_ID).remove();
        this.instance.$destroy();
        this.applyOptions(newOptions);
    }

    /**
     * Trigger submit lead event
     */
    resubmitLead() {
        // emit the resubmit lead event on the $root so any component can listen to it
        this.instance.$root.$emit('resubmit-lead');
    }

    /**
     * Initialize app to process components
     */
    initializeApp() {
        this.instance = new Vue({
            el         : `#${ADC_CONSENT_APP_ID}`,
            store,
            components : {
                AdcConsentApp,
                Consent
            },
            provide    : {
                onSubmitCallback             : this.onSubmitCallback,
                preLeadSubmitCallback        : this.preLeadSubmitCallback,
                postLeadSubmitCallback       : this.postLeadSubmitCallback,
                preModalCallback             : this.preModalCallback,
                postAcceptCallback           : this.postAcceptCallback,
                postRejectCallback           : this.postRejectCallback,
                necessaryFieldsForSubmission : this.necessaryFieldsForSubmission,
                treatFormDataFilter          : this.treatFormDataFilter
            },
            i18n       : translations(this.language)
        });
    }
}

window.adcConsent = options => {
    return new AdcConsent(options);
};
