const LOGGER_EVENT_NAME    = 'logger_event';
const LOGGER_TYPE_KEY      = 'logger_type';
const EVENT_KEY            = 'event';
const LOGGER_MESSAGE_KEY   = 'logger_message';
const LOGGER_CONTEXT_KEY   = 'logger_context';
const LOG_TYPE_ERROR_VALUE = 'error';

export default class Logger {
    constructor() {
    }

    /**
     * Log errors
     *
     * @param {string} message
     * @param {object} context
     */
    logError(message, context) {
        context[LOGGER_TYPE_KEY] = LOG_TYPE_ERROR_VALUE;

        this.dataLayerPush({[LOGGER_MESSAGE_KEY]: message});
        this.dataLayerPush({[LOGGER_CONTEXT_KEY]: JSON.stringify(context)});
        this.dataLayerPush({[EVENT_KEY]: LOGGER_EVENT_NAME});
    }

    /**
     * Push events or variables to TagManager DataLayer
     *
     * @param {object} objectData
     */
    dataLayerPush(objectData) {
        if (Object.keys(objectData).length > 0 && typeof dataLayer !== 'undefined') {
            dataLayer.push(objectData);
        }
    }

}
