import {mapGetters, mapActions} from 'vuex';
import ErrorMessage             from '../error-message';
import LoadingText              from '../loading-text';

export default {
    components : {
        ErrorMessage,
        LoadingText
    },
    data() {
        return {
            allPartnersSelected : true,
            selectedPartners    : [],
            showPartners        : false
        };
    },
    computed   : {
        ...mapGetters('leadcentre', {
            lcGetCampaigns : 'getCampaigns',
            lcLoaded       : 'apiLoaded',
            lcError        : 'apiError',
            lcGetList      : 'getList'
        }),
        ...mapGetters('partners', {
            storeGetPartners : 'getPartners'
        }),
        partners() {
            return this.storeGetPartners;
        },
        partnersList() {
            return this.lcGetList;
        }
    },
    methods    : {
        ...mapActions('leadcentre', {
            lcToggleCampaigns : 'toggleAllOptionalCampaigns'
        }),

        isPartnerSelected(partner) {
            return this.selectedPartners.indexOf(partner) >= 0;
        },

        preventPartnersDisclosure(target) {
            // only toggle the partners when clicking an underlined element or the OK button
            return (target.tagName.toLowerCase() !== 'u' && target.className !== 'adc-consent-modal-button' && target.className !== 'adc-consent-modal-close');
        },

        clickPartnersDisclosure(event) {
            if (this.preventPartnersDisclosure(event.target)) {
                event.stopPropagation();

                return;
            }

            this.showPartners        = !this.showPartners;
            this.allPartnersSelected = (this.selectedPartners.length === this.storeGetPartners.length);
            this.lcToggleCampaigns(this.allPartnersSelected);
        }
    }
};
