const messages = {
    pt: {
        accept: 'Aceito',
        refuse: 'Não aceito',
        ok: 'Ok',
        lc_api_error: 'Lamentamos, mas ocorreu um erro inesperado. Por favor tente mais tarde.',
        modal: {
            header: 'Confirme o seu consentimento'
        },
        partners: {
            header: 'Escolha as opções de informação e publicidade deste site e dos seus parceiros',
            disclaimer: 'Clique no link <u>parceiros</u> para ver as opções de conteúdo e publicidade.',
            all: 'Todos',
            culture: 'Cultura, Entretenimento e Lazer',
            health: 'Saúde, Bem-estar e Alimentação',
            science: 'Ciência, Tecnologia e Telecomunicações',
            finance: 'Finanças, Banca e Seguros',
            education: 'Educação, Formação e Emprego',
            real_estate: 'Imobiliário',
            tourism: 'Turismo e Viagens',
            energy: 'Energia, Combustíveis, Água e Ambiente'
        },
        select: {
            choose: 'Escolha uma opção',
            accept: 'Sim, aceito',
            refuse: 'Não aceito',
            all:    'Selecionar todos'
        },
        radio: {
            accept: 'Sim',
            refuse: 'Não',
            accept_terms: 'É necessário aceitar os Termos e Condições e Política de Privacidade para continuar'
        }
    },
    en: {
        accept: 'Accept',
        refuse: 'Refuse',
        ok: 'Ok',
        lc_api_error: 'Sorry, an unexpected error has occurred. Please try again later.',
        modal: {
            header: 'Confirm your consent'
        },
        partners: {
            header: 'Choose the information and publicity options for this website and its partners',
            disclaimer: 'Click the <u>partners</u> link to adjust the content and publicity options.',
            all: 'All',
            culture: 'Culture, Entertainment and Leisure',
            health: 'Health, Well-being and Food',
            science: 'Science, Technology and Telecommunications',
            finance: 'Bank, Finance and Insurance',
            education: 'Education, Courses and Jobs',
            real_estate: 'Real Estate',
            tourism: 'Tourism and Travel',
            energy: 'Energy, Fuel, Water and Environment'
        },
        select: {
            choose: 'Choose an option',
            accept: 'Yes, I accept',
            refuse: 'No, I refuse',
            all:    'Select all'
        },
        radio: {
            accept: 'Yes',
            refuse: 'No',
            accept_terms: 'You must accept the Terms and Conditions and Privacy Policy to continue'
        }
    },
    fr: {
        accept: 'J\'accepte',
        refuse: 'Je refuse',
        ok: 'Ok',
        lc_api_error: 'Une erreur inattendue s\'est produite. Veuillez réessayer plus tard.',
        modal: {
            header: 'Confirmez votre consentement'
        },
        partners: {
            header: 'Choisissez las options de contenu et de publicité de ce site web et de ses partenaires',
            disclaimer: 'Cliquez sur le lien des <u>partenaires</u> pour regarder les options de contenu et de publicité.',
            all: 'Tous',
            culture: 'Culture, divertissement et Loisirs',
            health: 'Santé, Bien-être et Alimentation',
            science: 'Science, Technologie et télécommunications',
            finance: 'Banque, Finance et Assurance',
            education: 'Education, Formation et Emploi',
            real_estate: 'Immobilier',
            tourism: 'Tourisme et Voyage',
            energy: 'Énergie, Combustibles, Eau et Environnement'
        },
        select: {
            choose: 'Choisissez une option',
            accept: 'Oui, j\'accepte',
            refuse: 'Je n\'accepte pas',
            all:    'Tout sélectionner'
        },
        radio: {
            accept: 'Oui',
            refuse: 'Non',
            accept_terms: 'Vous devez accepter les conditions générales et la politique de confidentialité pour continuer'
        }
    },
    es: {
        accept: 'Acepto',
        refuse: 'No acepto',
        ok: 'Ok',
        lc_api_error: 'Se ha producido un error inesperado. Vuelva a intentarlo más tarde.',
        modal: {
            header: 'Confirme su consentimiento'
        },
        partners: {
            header: 'Elija las opciones de contenido y publicidad de esto sitio web y de sus colaboradores',
            disclaimer: 'Haga clic en el enlace de <u>colaboradores</u> para ver las opciones de contenido y publicidad.',
            all: 'Todos',
            culture: 'Cultura, Entretenimiento y Ocio',
            health: 'Salud, Bienestar y Alimentación',
            science: 'Ciencia, Tecnología y Telecomunicaciones',
            finance: 'Finanzas, Banca y Seguros',
            education: 'Educación, Formación y Empleo',
            real_estate: 'Sector Inmobiliario',
            tourism: 'Turismo y Viajes',
            energy: 'Energía, Carburantes, Agua y Medio Ambiente'
        },
        select: {
            choose: 'Escoja una opción',
            accept: 'Si, acepto',
            refuse: 'No, no acepto',
            all:    'Seleccionar todo'
        },
        radio: {
            accept: 'Sí',
            refuse: 'No',
            accept_terms: 'Debe aceptar los Términos y condiciones y la Política de privacidad para continuar.'
        }
    },
    pl: {
        accept: 'Akceptuję',
        refuse: 'Nie akceptuję',
        ok: 'Ok',
        lc_api_error: 'Przepraszamy, wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
        modal: {
            header: 'Potwierdź Twoją zgodę'
        },
        partners: {
            header: 'Wybierz opcje treści i reklam dla tej witryny i jej partnerów',
            disclaimer: 'Kliknij link <u>partnerzy</u>, aby dostosować opcje treści i reklamy.',
            all: 'Wszyscy',
            culture: 'Kultura, rozrywka i wypoczynek',
            health: 'Zdrowie, uroda i odżywanie',
            science: 'Nauka, technologia i telekomunikacja',
            finance: 'Finanse, bankowość i ubezpieczenia',
            education: 'Edukcja, szkolenia i praca',
            real_estate: 'Nieruchomości',
            tourism: 'Turystyka i podróże',
            energy: 'Energia, paliwa, woda i środowisko'
        },
        select: {
            choose: 'Wybierz opcję',
            accept: 'Tak, akceptuję',
            refuse: 'Nie akceptuję',
            all:    'Zaznacz wszystko'
        },
        radio: {
            accept: 'Tak',
            refuse: 'Nie',
            accept_terms: 'Aby kontynuować, musisz zaakceptować Regulamin i Politykę prywatności.'
        }
    },
    br: {
        accept: 'Aceito',
        refuse: 'Não aceito',
        ok: 'Ok',
        lc_api_error: 'Lamentamos, mas ocorreu um erro inesperado. Por favor tente mais tarde.',
        modal: {
            header: 'Confirme o seu consentimento'
        },
        partners: {
            header: 'Escolha as categorias dos nossos parceiros sobre as quais deseja receber comunicações',
            disclaimer: 'Clique no link <u>parceiros</u> para ver as opções de conteúdo e publicidade.',
            all: 'Todos',
            culture: 'Cultura, Entretenimento e Lazer',
            health: 'Saúde, Bem-estar e Alimentação',
            science: 'Ciência, Tecnologia e Telecomunicações',
            finance: 'Finanças, Banca e Seguros',
            education: 'Educação, Formação e Emprego',
            real_estate: 'Imobiliário',
            tourism: 'Turismo e Viagens',
            energy: 'Energia, Combustíveis, Água e Ambiente'
        },
        select: {
            choose: 'Escolha uma opção',
            accept: 'Sim, aceito',
            refuse: 'Não aceito',
            all:    'Selecionar todos'
        },
        radio: {
            accept: 'Sim',
            refuse: 'Não',
            accept_terms: 'É necessário aceitar os Termos e Condições e Política de Privacidade para continuar'
        }
    },
    nl: {
        accept: 'Ik ga akkoord',
        refuse: 'Ik ga niet accord',
        ok: 'Ok',
        lc_api_error: 'Sorry, an unexpected error has occurred. Please try again later.',
        modal: {
            header: 'Confirm your consent'
        },
        partners: {
            header: 'Onze partners',
            disclaimer: 'Click the <u>partners</u> link to adjust the content and publicity options.',
            all: 'Alle',
            culture: 'Cultuur, amusement en vrijetijdsbesteding',
            health: 'Salle Gezondheid, welzijn en voeding',
            science: 'Wetenschap, technologie en telecommunicatie',
            finance: 'Bank, financiële en verzekerings',
            education: 'Onderwijs, opleiding en werkgelegenheid',
            real_estate: 'Vastgoed',
            tourism: 'Toerisme en reizen',
            energy: 'Energie, brandstoffen, water en milieu'
        },
        select: {
            choose: 'Optie kiezen',
            accept: 'Ja, Ik ga akkoord',
            refuse: 'Nee, Ik ga niet accord',
            all:    'Selecteren alle'
        },
        radio: {
            accept: 'Ja',
            refuse: 'Nee',
            accept_terms: 'U moet de algemene voorwaarden en het privacybeleid accepteren om door te gaan'
        }
    },
};

export default messages;
