/**
 * Returns translation language according to given
 *
 * @returns string
 */
function getNormalizedLanguage(language) {
    const PT_LANGUAGES = ['pt', 'pt-pt', 'pt_pt'];
    const ES_LANGUAGES = ['es', 'es-es', 'es_es', 'mx'];
    const EN_LANGUAGES = ['en', 'en-en', 'gb', 'en-gb', 'uk', 'en-uk', 'us', 'en-us'];
    const FR_LANGUAGES = ['fr', 'fr-fr', 'fr-be', 'fr_fr', 'fr_be', 'be'];
    const PL_LANGUAGES = ['pl', 'pl-pl', 'pl_pl'];
    const BR_LANGUAGES = ['br', 'pt-br', 'pt_br'];
    const NL_LANGUAGES = ['nl', 'nl-nl', 'nl-be'];

    if (PT_LANGUAGES.indexOf(language) >= 0) {
        return 'pt';
    }

    if (ES_LANGUAGES.indexOf(language) >= 0) {
        return 'es';
    }

    if (EN_LANGUAGES.indexOf(language) >= 0) {
        return 'en';
    }

    if (FR_LANGUAGES.indexOf(language) >= 0) {
        return 'fr';
    }

    if (PL_LANGUAGES.indexOf(language) >= 0) {
        return 'pl';
    }

    if (BR_LANGUAGES.indexOf(language) >= 0) {
        return 'br';
    }

    if (NL_LANGUAGES.indexOf(language) >= 0) {
        return 'nl';
    }

    return language;
}

export default {
    normalize: getNormalizedLanguage
};
