


export default {
    template : require('./accordion.vue'),
    name: 'accordion',
    props: {},
    data() {
        return {
            Accordion: {
                count: 0,
                active: null
            }
        };
    },
    provide() {
        return { Accordion: this.Accordion };
    }
};