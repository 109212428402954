import {mapGetters}    from 'vuex';
import CryptoJS_SHA256 from 'crypto-js/sha256';
import ErrorMessage    from '../error-message';
import LoadingText     from '../loading-text';

export default {
    template   : require('./injected.vue'),
    components : {
        ErrorMessage,
        LoadingText
    },
    computed   : {
        ...mapGetters('leadcentre', {
            lcGetCampaigns : 'getCampaigns',
            lcLoaded       : 'apiLoaded',
            lcError        : 'apiError'
        })
    },
    methods    : {

        getTermsAndConditions(required) {
            const campaigns = this.lcGetCampaigns;

            let campaignList = [];
            let hashes       = {};

            campaigns.forEach(campaign => {
                let hash = CryptoJS_SHA256(campaign.consent.text);

                if (campaign.consent.required !== required || hashes.hasOwnProperty(hash)) {
                    return;
                }

                hashes[hash] = campaign.consent.text;
                campaignList.push({campaign : campaign.consent.text, ids : [campaign.id]});
            });

            return campaignList;
        }
    }
};
