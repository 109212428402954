const namespaced = true;
let partnersList = [];

const getters = {
    getPartners() {
        return partnersList;
    },
};


const setters = {
    setPartners(verticals) {
        verticals.map(item => {
            const name = item[0].toLowerCase();
            partnersList.push({name: 'partners.' + name, value: name});
        });
    },
};

export const partners = {
    namespaced,
    getters,
    setters
};
