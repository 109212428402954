<script>
    export default {
        name: 'adc-consent-app'
    }
</script>
<style lang="scss" src="./assets/styles/app.scss"></style>
<template>
    <div>
        <slot></slot>
    </div>
</template>
