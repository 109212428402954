import Vue from 'vue';
import Vuex from 'vuex';
import {leadcentre} from './modules/leadcentre';
import {partners} from './modules/partners';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const store = {
    state: {
        version: '1.0.0'
    },
    strict: debug,
    modules: {
        leadcentre,
        partners
    }
};

export default new Vuex.Store(store);
