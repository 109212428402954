import {mapGetters} from 'vuex';

const existingCampaignsIds = [];

export default {
    template : require('./campaign-chks.vue'),
    props    : {
        sourcePageId : 0
    },
    computed : {
        ...mapGetters('leadcentre', {
            lcGetCampaigns : 'getCampaigns'
        })
    },
    mounted() {
        const existingCampaigns = document.querySelectorAll('input[name^="chk"]');

        // save all existing chk ids to only display campaigns that are not already on the page
        for (const campaign in existingCampaigns) {
            if (!existingCampaigns.hasOwnProperty(campaign)) {
                continue;
            }

            const campaignChk = existingCampaigns[campaign];
            const chkName     = campaignChk.getAttribute('name');
            const matches     = chkName.match(/\[(.*?)]/);

            if (matches === null || matches.length <= 0) {
                continue;
            }

            const id = parseInt(matches[1]);

            if (existingCampaignsIds.indexOf(id) < 0) {
                existingCampaignsIds.push(id);
            }
        }
    },
    methods  : {
        getCampaigns() {
            const lcCampaigns = this.lcGetCampaigns;

            return lcCampaigns.filter(campaign => existingCampaignsIds.indexOf(campaign.id) < 0);
        },

        getInputName(id) {
            return 'chk[' + id + ']';
        },

        getForm() {
            return this.$refs.sourcePageInput.form;
        }
    }
};
