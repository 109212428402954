import {mapActions, mapGetters} from 'vuex';
import CampaignChks             from './campaign-chks';
import Modal                    from './themes/modal';
import Smarkio                  from './themes/smarkio';
import CheckBoxV2               from './themes/check-box-v2';
import Toggle                   from './themes/toggle';
import Injected                 from './themes/injected';
import * as Cookies             from 'js-cookie';
import LeadManager              from '../services/lead-manager';
import Logger                   from '../services/log-service.js';

const COOKIE_KEY = 'leadcentreSourcePage';

const THEME_MODAL     = 'modal';
const THEME_INHERIT   = 'inherit';
const THEME_CHECK_V2  = 'check-v2';
const THEME_INJECTED  = 'injected';
const THEME_LEADSHARE = 'leadshare';
const THEME_SMARKIO   = 'smarkio';
const THEME_TOGGLE    = 'toggle';

export default {
    template   : require('./consent.vue'),
    components : {
        CampaignChks,
        Modal,
        Smarkio,
        Injected,
        CheckBoxV2,
        Toggle
    },
    inject     : {
        onSubmitCallback             : 'onSubmitCallback',
        preLeadSubmitCallback        : 'preLeadSubmitCallback',
        postLeadSubmitCallback       : 'postLeadSubmitCallback',
        preModalCallback             : 'preModalCallback',
        necessaryFieldsForSubmission : 'necessaryFieldsForSubmission',
        treatFormDataFilter          : 'treatFormDataFilter'
    },
    props      : {
        buttonClass       : '',
        theme             : '',
        submitLead        : false,
        leadcentreToken   : '',
        sourcePageId      : 0,
        sourceDomain        : '',
        modalOpen         : false,
        modalStrikePartialPartners : true,
        checkSelectAll    : false,
        elementIdToInject : ''
    },
    computed   : {
        ...mapGetters('leadcentre', {
            lcCampaignsForCookie : 'getCampaignsForCookie',
            lcError              : 'apiError',
            lcGetCampaigns       : 'getCampaigns'
        })
    },
    data() {
        return {
            showModal                 : false,
            strikePartialPartners     : true,
            googleAnalyticsCid        : 0,
            googleAnalyticsPropertyId : '',
            utmFields                 : []
        };
    },
    created() {
        // listen to the resubmit lead event
        this.$root.$on('resubmit-lead', this.resubmitLead);

        this.logger = new Logger();
    },
    mounted() {
        this.lcFetchData({
            leadcentreAPIToken : this.leadcentreToken,
            sourcePageId       : this.sourcePageId,
            sourceDomain       : this.sourceDomain,
            fromCookie         : this.isThemeInherit()
        });

        if (this.modalOpen === true) {
            this.showModal = true;
        }

        if (this.modalStrikePartialPartners === false) {
            this.strikePartialPartners = false;
        }

        if (this.isThemeModal() || this.isThemeSmarkio()) {
            this.$refs.consentButton.getElementsByClassName(this.buttonClass)[0].onclick = this.openModal;
        }

        if (
            this.isThemeLeadshare() ||
            this.isThemeCheckV2() ||
            this.isThemeInherit() ||
            this.isThemeToggle()
        ) {
            this.$refs.consentButton.getElementsByClassName(this.buttonClass)[0].onclick = this.onSubmit;
        }

        if (this.isThemeInjected()) {
            document.getElementsByClassName(this.buttonClass)[0].onclick = this.onSubmit;
        }

        // add additional fields to be submitted, such as GA cid and UTM fields
        if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
            this.addAdditionalFields();
        } else {
            window.addEventListener('load', this.addAdditionalFields);
        }
    },
    methods    : {
        ...mapActions('leadcentre', {
            lcFetchData  : 'fetchData',
            lcSubmitLead : 'submitLead'
        }),

        isThemeModal() {
            return this.theme === THEME_MODAL;
        },

        isThemeInherit() {
            return  this.theme === THEME_INHERIT;
        },

        isThemeCheckV2() {
            return this.theme === THEME_CHECK_V2;
        },

        isThemeInjected() {
            return this.theme === THEME_INJECTED;
        },

        isThemeLeadshare() {
            return this.theme === THEME_LEADSHARE;
        },

        isThemeSmarkio() {
            return this.theme === THEME_SMARKIO;
        },

        isThemeToggle() {
            return this.theme === THEME_TOGGLE;
        },

        /**
         * Handle submit button click for inherit, check and select themes
         *
         * @param {Event} event
         */
        onSubmit(event) {
            if (this.submitLead) {
                event.preventDefault();
            }

            if (
                this.isThemeCheckV2() ||
                this.isThemeToggle()) {
                this.addToCookie();
            }

            if (this.submitLead) {
                this.submitForm();
            }

            this.onSubmitCallback();
        },

        /**
         * Submit lead triggered by submit lead event
         */
        resubmitLead() {
            if (
                this.isThemeCheckV2() ||
                this.isThemeToggle()) {
                this.addToCookie();
            }

            if (this.isThemeSmarkio()) {
                this.submitSmarkioLead();
            } else {
                this.submitForm();
            }

            this.onSubmitCallback();
        },

        /**
         * Handle submit button click for modal and smarkio theme
         *
         * @param {Event} event
         */
        openModal(event) {
            event.preventDefault();

            let canOpenModal = this.preModalCallback();

            if (canOpenModal === true) {
                this.showModal = true;

                return;
            }

            // if call is a promise or a deferred object, such as jqXHR, wait for the result
            if (canOpenModal instanceof Promise || canOpenModal.promise) {
                Promise.resolve(canOpenModal).then(result => {
                    if (result === true) {
                        this.showModal = true;
                    }
                });
            }
        },

        /**
         * Handle cancel button click for modal and smarkio theme
         *
         * @param accepted
         */
        closeModal(accepted) {
            if (accepted && this.isThemeModal()) {
                if (this.submitLead) {
                    this.submitForm();
                }

                this.addToCookie();
            }

            if (accepted && this.isThemeSmarkio() && this.submitLead) {
                this.submitSmarkioLead();
            }

            this.showModal = false;
        },

        /**
         * Set cookie with the campaign consents
         */
        addToCookie() {
            const cookieContent = this.lcCampaignsForCookie;

            const thirtyMinutesFromNow = new Date();
            thirtyMinutesFromNow.setMinutes(thirtyMinutesFromNow.getMinutes() + 30);

            Cookies.set(COOKIE_KEY, cookieContent, {expires : thirtyMinutesFromNow, domain : this.sourceDomain});
        },

        /**
         * Check form validity and pre submit callback to submit the form
         */
        submitForm() {
            const form          = this.$refs.campaignChks.getForm();
            const canSubmitLead = this.preLeadSubmitCallback();

            if (typeof form === 'object' && typeof form.reportValidity === 'function' && form.reportValidity() === false) {
                return;
            }

            if (canSubmitLead === false) {
                return;
            }

            let formData = new FormData(form);
            formData     = this.treatFormDataFilter(formData);

            // if call is a promise or a deferred object, such as jqXHR, wait for the result
            if (canSubmitLead instanceof Promise || canSubmitLead.promise) {
                Promise.resolve(canSubmitLead).then(result => {
                    if (result === true) {
                        this.handleLeadSubmission(formData);
                    }
                });

                return;
            }

            this.handleLeadSubmission(formData);
        },

        /**
         * Check pre submit callback to submit the smarkio lead
         */
        submitSmarkioLead() {
            const canSubmitLead = this.preLeadSubmitCallback();

            if (canSubmitLead === false) {
                return;
            }

            const smarkioData = App.Chat.variables;

            // if call is a promise or a deferred object, such as jqXHR, wait for the result
            if (canSubmitLead instanceof Promise || canSubmitLead.promise) {
                Promise.resolve(canSubmitLead).then(result => {
                    if (result === true) {
                        this.handleLeadSubmission(smarkioData);
                    }
                });

                return;
            }

            this.handleLeadSubmission(smarkioData);
        },

        /**
         * Handle lead submission to the store
         *
         * @param data
         */
        async handleLeadSubmission(data) {
            // convert data into object
            let leadData;

            if (this.isThemeSmarkio()) {
                leadData = LeadManager.convertObjectIntoLeadcentreFormat(data);
            } else {
                leadData = LeadManager.convertFormDataIntoLeadcentreFormat(data);
            }

            // check if object has necessary fields for submission
            if (!LeadManager.checkNecessaryFieldsForSubmission(leadData.lead, this.necessaryFieldsForSubmission)) {
                this.postLeadSubmitCallback();

                return;
            }

            const campaigns = this.lcGetCampaigns;

            // add default attributes to lead
            leadData = await LeadManager.addDefaultAttributesToLead(leadData, campaigns);

            // check if terms were accepted and we can submit
            if (!LeadManager.checkTermsAccepted(leadData)) {
                this.postLeadSubmitCallback();

                return;
            }

            // submit lead
            this.lcSubmitLead(leadData).then(responseData => {
                this.postLeadSubmitCallback(responseData);

                // trigger event for successful lead submission
                this.logger.dataLayerPush({'event': 'lcLeadSubmit', 'ecData': {'email': leadData.lead.email}});

                // trigger event for Purpose Consent Accepted
                this.logger.dataLayerPush({'event': 'lcConsentAcceptedPurpose'});

                // trigger event for Optional Consent Accepted
                if (LeadManager.checkOptionalTermsAccepted(campaigns)) {
                    this.logger.dataLayerPush({'event': 'lcConsentAcceptedOptional'});
                }
            }).catch(error => {
                console.error('Unable to submit contact', error);
            });
        },

        addAdditionalFields() {
            this.updateGoogleAnalyticsClientId();
            this.updateGoogleAnalyticsPropertyId();
            this.updateUTMFields();
        },

        updateGoogleAnalyticsClientId() {
            try {
                // check if one of our products have set the GA id in a custom window variable
                if (window.gaCid) {
                    this.googleAnalyticsCid = window.gaCid;

                    return;
                }

                const tracker = window.ga.getAll()[0];

                this.googleAnalyticsCid = tracker.get('clientId');
            } catch (e) {
                console.log('GA Cid could not be retrieved.');
            }
        },

        updateGoogleAnalyticsPropertyId() {
            if (window.dataLayer) {
                for (let i = 0; i < dataLayer.length; i++) {
                    if (typeof dataLayer[i]['analyticsViewId'] !== 'undefined') {
                        this.googleAnalyticsPropertyId = dataLayer[i]['analyticsViewId']['vtp_trackingId'];

                        return;
                    }
                }
            }
        },

        updateUTMFields() {
            const urlParams = new URLSearchParams(window.location.search);

            for (const [key, value] of urlParams) {
                if (key.indexOf('utm') >= 0) {
                    this.utmFields.push({field : key, value : value});
                }
            }
        }
    }
};
