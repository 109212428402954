import {LeadCentreApiClient} from '../../api/lead-centre-api-client';
import * as Cookies          from 'js-cookie';
import {partners} from './partners';

const LEADCENTRE_API_LOADED                = 'LEADCENTRE_API_LOADED';
const LEADCENTRE_API_ERROR                 = 'LEADCENTRE_API_ERROR';
const LEADCENTRE_TOGGLE_OPTIONAL_CAMPAIGNS = 'LEADCENTRE_TOGGLE_OPTIONAL_CAMPAIGNS';
const LEADCENTRE_TOGGLE_CAMPAIGNS          = 'LEADCENTRE_TOGGLE_CAMPAIGNS';
const LEADCENTRE_INHERIT_CAMPAIGNS         = 'LEADCENTRE_INHERIT_CAMPAIGNS';

const COOKIE_KEY = 'leadcentreSourcePage';

const namespaced = true;

const state = {
    sourcePage: null,
    error:      false,
    loaded:     false
};

let leadcentreApi;

const getters = {
    apiLoaded: state => state.loaded,
    apiError: state => state.error,

    getCampaigns(state) {
        return (state.sourcePage && state.sourcePage.campaigns) ? state.sourcePage.campaigns : [];
    },

    getList(state) {
        return (state.sourcePage && state.sourcePage.verticals) ? Object.entries(state.sourcePage.verticals) : [];
    },

    getCampaignsForCookie(state) {
        let campaigns = {};

        if (!state.sourcePage || !state.sourcePage.campaigns) {
            return '';
        }

        state.sourcePage.campaigns.forEach(campaign => {
            campaigns[campaign.id] = campaign.selected;
        });

        // merge with campaigns previously consented
        let cookieContent   = Cookies.get(COOKIE_KEY) || btoa('{}');
        let cookieCampaigns = Object.entries(JSON.parse(atob(cookieContent)));

        cookieCampaigns.forEach(campaign => {
            campaigns[parseInt(campaign[0])] = campaign[1];
        });

        return btoa(JSON.stringify(campaigns));
    }
};

const actions = {
    fetchData({commit}, {leadcentreAPIToken, sourcePageId, sourceDomain, fromCookie}) {
        leadcentreApi = new LeadCentreApiClient(leadcentreAPIToken, sourcePageId, sourceDomain);

        leadcentreApi
            .getSourcePage()
            .then(sourcePage => {
                partners.setters.setPartners(Object.entries(sourcePage.verticals));

                commit(LEADCENTRE_API_LOADED, sourcePage);

                if (fromCookie) {
                    let cookieContent   = Cookies.get(COOKIE_KEY) || btoa('{}');
                    let cookieCampaigns = JSON.parse(atob(cookieContent));

                    commit(LEADCENTRE_INHERIT_CAMPAIGNS, {cookieCampaigns});
                } else {
                    commit(LEADCENTRE_TOGGLE_OPTIONAL_CAMPAIGNS, {isSelected: true});
                }
            }, () => {
                commit(LEADCENTRE_API_ERROR);
            });
    },

    submitLead(undefined, leadData) {
        return leadcentreApi.submitLead(leadData);
    },

    toggleAllOptionalCampaigns({commit}, isSelected) {
        commit(LEADCENTRE_TOGGLE_OPTIONAL_CAMPAIGNS, {isSelected});
    },

    toggleCampaigns({commit}, {campaignIds, isSelected}) {
        commit(LEADCENTRE_TOGGLE_CAMPAIGNS, {campaignIds, isSelected});
    }
};

const mutations = {
    [LEADCENTRE_API_LOADED](state, payload) {
        state.loaded     = true;
        state.error      = false;
        state.sourcePage = payload;
    },

    [LEADCENTRE_API_ERROR](state) {
        state.loaded     = true;
        state.error      = true;
        state.sourcePage = undefined;
    },

    [LEADCENTRE_TOGGLE_OPTIONAL_CAMPAIGNS](state, {isSelected}) {
        if (!state.sourcePage || !state.sourcePage.campaigns) {
            return;
        }

        state.sourcePage.campaigns.forEach((campaign, index) => {
            if (campaign.consent.required && !isSelected) {
                return;
            }

            // Splice is required so the array changes are reactive
            campaign.selected = isSelected;
            state.sourcePage.campaigns.splice(parseInt(index), 1, campaign);
        });
    },

    [LEADCENTRE_TOGGLE_CAMPAIGNS](state, {campaignIds, isSelected}) {
        if (!state.sourcePage || !state.sourcePage.campaigns) {
            return;
        }

        state.sourcePage.campaigns.forEach((campaign, index) => {
            if (campaign.consent.required && !isSelected) {
                return;
            }

            if (campaignIds.indexOf(campaign.id) >= 0) {

                // Splice is required so the array changes are reactive
                campaign.selected = isSelected;
                state.sourcePage.campaigns.splice(parseInt(index), 1, campaign);
            }
        });
    },

    [LEADCENTRE_INHERIT_CAMPAIGNS](state, {cookieCampaigns}) {
        if (!state.sourcePage || !state.sourcePage.campaigns) {
            return;
        }

        state.sourcePage.campaigns.forEach((campaign, index) => {
            campaign.selected = cookieCampaigns[campaign.id] || false;

            // Splice is required so the array changes are reactive
            state.sourcePage.campaigns.splice(parseInt(index), 1, campaign);
        });
    }
};

export const leadcentre = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
