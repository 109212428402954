import axios               from 'axios';
import axiosRetry          from 'axios-retry';
import CryptoJS_hmacSHA256 from 'crypto-js/hmac-sha256';
import CryptoJS_enc_Hex    from 'crypto-js/enc-hex';
import Logger              from '../services/log-service.js';

const API_CALL_SUCCESS_KEY                          = 'lc_api_call_success';
const API_CALL_ERROR_CODE_KEY                       = 'lc_api_call_error_code';
const API_CALL_ERROR_MESSAGE_KEY                    = 'lc_api_call_error_message';
const API_CALL_SOURCE_PAGE_KEY                      = 'lc_api_call_source_page';
const API_GET_SOURCE_PAGE_ERROR_INTERNAL_CODE_VALUE = '#LCC-0045# LC API call error';
const API_LEAD_SUBMIT_ERROR_INTERNAL_CODE_VALUE     = '#LCC-0046# LC API lead submit error';
const EVENT_KEY                                     = 'event';

export const API_GET_SOURCE_PAGE_CALL_RETURNED_INVALID_RESPONSE = '#LCC-0049# LC API invalid response';
export const API_GET_SOURCE_PAGE_CALL_RETURNED_NO_CAMPAIGNS     = 'LC API returned no campaigns';

const SOURCE_PAGE_ENDPOINT = 'api/source_page/';
const SUBMIT_LEAD_ENDPOINT = 'api/source_page/contact';

const AXIOS_CONFIG = {
    method        : 'GET',
    headers       : {
        'Accept'       : 'application/json',
        'Content-Type' : 'application/json'
    },
    'axios-retry' : {
        retries : 2
    }
};

axiosRetry(axios);

export class LeadCentreApiClient {
    constructor(token, sourcePageId, baseUrl) {
        this.token        = token;
        this.sourcePageId = sourcePageId;
        this.logger       = new Logger();
        this.isSubmitting = false;

        this.baseURL = 'https://' + baseUrl + '/';
    }

    /**
     * Get source page data from leadcentre API
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSourcePage() {
        let queryUrl = this.baseURL + SOURCE_PAGE_ENDPOINT + this.sourcePageId,
            config   = Object.assign({}, AXIOS_CONFIG);

        return axios.get(queryUrl, config)
            .then(response => {
                this.logSuccessApiCalls();

                if (!this.validateGetSourcePageResponse(response.data)) {

                    return Promise.reject();
                }

                return response.data.data;
            }, error => {
                const context                       = {};
                context[API_CALL_ERROR_CODE_KEY]    = error.response.data.code;
                context[API_CALL_ERROR_MESSAGE_KEY] = error.response.data.message;
                context[API_CALL_SOURCE_PAGE_KEY]   = this.sourcePageId;

                this.logger.logError(API_GET_SOURCE_PAGE_ERROR_INTERNAL_CODE_VALUE, context);
                console.error(API_GET_SOURCE_PAGE_ERROR_INTERNAL_CODE_VALUE, error);

                return Promise.reject();
            });
    }

    /**
     * Submits lead to leadcenter API
     *
     * @param leadData
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    submitLead(leadData) {
        let queryUrl = this.baseURL + SUBMIT_LEAD_ENDPOINT,
            config   = Object.assign({}, AXIOS_CONFIG,
                {
                    headers : {
                        Authorization : 'Basic ' + this.generateAuthorization(this.sourcePageId)
                    }
                }
            );

        if (this.isSubmitting) {
            return;
        }

        this.isSubmitting = true;

        // delay next submission
        setTimeout(() => {
            this.isSubmitting = false;
        }, 5000);

        return axios.post(queryUrl, leadData, config)
            .then(
                response => {
                    this.logSuccessApiCalls();

                    return Promise.resolve(response.data.data);
                },
                error => {
                    const context                       = {};
                    context[API_CALL_ERROR_CODE_KEY]    = error.response.data.code;
                    context[API_CALL_ERROR_MESSAGE_KEY] = error.response.data.message;
                    context[API_CALL_SOURCE_PAGE_KEY]   = this.sourcePageId;

                    this.logger.logError(API_LEAD_SUBMIT_ERROR_INTERNAL_CODE_VALUE, context);
                    console.error(API_LEAD_SUBMIT_ERROR_INTERNAL_CODE_VALUE, error);

                    return Promise.reject();
                }
            );
    }

    /**
     *  To be removed, only here so we are able to count total API calls in a time range
     */
    logSuccessApiCalls() {
        const successEvent      = {};
        successEvent[EVENT_KEY] = API_CALL_SUCCESS_KEY;

        if (typeof dataLayer !== 'undefined') {
            dataLayer.push(successEvent);
        }
    }

    /**
     * Generate authorization token for a source page
     *
     * @param sourcePageId
     *
     * @returns {string}
     */
    generateAuthorization(sourcePageId) {
        let dateNow = new Date();
        dateNow.setTime(dateNow.getTime() + (60 * 60 * 1000));

        let randomString = (Math.random() + 1).toString(36).substring(2);

        let message = `{"sourcePageId": ${sourcePageId}, "nonce": "${btoa(randomString)}", "expires": "${dateNow.toISOString()}"}`;

        return btoa(CryptoJS_hmacSHA256(message, this.token).toString(CryptoJS_enc_Hex) + message);
    }

    /**
     * Validate Get Source Page Response
     *
     * @param {Object} response
     *
     * @return {boolean}
     */
    validateGetSourcePageResponse(response) {
        const context                     = {};
        context[API_CALL_SOURCE_PAGE_KEY] = this.sourcePageId;

        // no valid response returned
        if (typeof response !== 'object' ||
            !response.hasOwnProperty('status') ||
            response.status !== 'ok' ||
            !response.hasOwnProperty('data') ||
            !response.data.hasOwnProperty('campaigns') ||
            !(response.data.campaigns instanceof Array)
        ) {
            this.logger.logError(API_GET_SOURCE_PAGE_CALL_RETURNED_INVALID_RESPONSE, context);
            console.error(API_GET_SOURCE_PAGE_CALL_RETURNED_INVALID_RESPONSE);

            return false;
        }

        const campaingsCount = response.data.campaigns.length;

        // no campaigns data returned
        if (campaingsCount === 0) {
            console.error(API_GET_SOURCE_PAGE_CALL_RETURNED_NO_CAMPAIGNS);

            return false;
        }

        return true;
    }
}
