import Modal from './modal';

const TERMS_ACCEPTED_CHAT_VARIABLE         = 'adc_terms_accepted'; //TO BE DEPRECATED
const USER_ACCEPTED_CHAT_VARIABLE          = 'adc_consent_user_accepted'; //WE SHOULD USE ONLY THIS ONE
const TERMS_NEWSLETTER_CHAT_VARIABLE       = 'adc_consent_newsletter'; //TO BE DEPRECATED
const TERMS_NEWSLETTER_CHAT_VARIABLE_EXTRA = 'extra[adc_consent_newsletter]'; //TO BE DEPRECATED
const TERMS_OWN_CHAT_VARIABLE              = 'adc_consent_own'; //TO BE DEPRECATED
const TERMS_OWN_CHAT_VARIABLE_EXTRA        = 'extra[adc_consent_own]'; //TO BE DEPRECATED
const TERMS_PARTNERS_CHAT_VARIABLE         = 'adc_consent_partners'; //TO BE DEPRECATED
const TERMS_PARTNERS_CHAT_VARIABLE_EXTRA   = 'extra[adc_consent_partners]'; //TO BE DEPRECATED
const ADC_CONSENT_QUESTIONS_KEY            = 'adc_consent_questions';
const ADC_CONSENT_QUESTIONS_EXTRA_KEY      = 'extra[adc_consent_questions]';
const ADC_CONSENT_QUESTION_KEY             = 'adc_consent_question';
const ADC_CONSENT_ANSWER_KEY               = 'adc_consent_answer';
const ADC_CONSENT_QUESTION_NUMBER_KEY      = 'adc_consent_question_number';
const YES_KEY                              = 'yes';
const NO_KEY                               = 'no';

const smarkio = {
    template : require('./smarkio.vue'),
    props    : {
        ...Modal.props,
        buttonClass               : '',
        leadcentreToken           : '',
        sourcePageId              : 0,
        googleAnalyticsCid        : 0,
        googleAnalyticsPropertyId : '',
        strikePartialPartners     : true
    },
    watch    : {
        lcLoaded : function (value) {
            if (value === true) {
                this.setCampaignsInSmarkioVariables(true);
            }
        }
    },
    components : {
        ...Modal.components
    },
    computed : {
        ...Modal.computed
    },
    methods  : {
        ...Modal.methods,
        setCampaignsInSmarkioVariables(hidden) {
            const campaigns = this.lcGetCampaigns;

            let partnersCampaigns         = false;
            let partnersCampaignsAccepted = NO_KEY;
            let ownCampaigns              = false;
            let ownCampaignsAccepted      = NO_KEY;

            let adcQuestions     = {};
            let adcFirstQuestion = '';

            App.Chat.addVariable('source_page_id', this.sourcePageId, 'source_page_id');
            App.Chat.addVariable('token', this.leadcentreToken, 'token');
            App.Chat.addVariable('google_analytics_cid', this.googleAnalyticsCid, 'google_analytics_cid');
            App.Chat.addVariable('google_analytics_property_id', this.googleAnalyticsPropertyId, 'google_analytics_property_id');

            campaigns.forEach(campaign => {

                //modal is displayed
                if (!hidden) {
                    App.Chat.addVariable(`chk[${campaign.id}]`, campaign.selected ? YES_KEY : NO_KEY, `chk[${campaign.id}]`);

                    switch (campaign.consent.contentType) {
                    case 'partner':
                        partnersCampaigns = true;

                        if (campaign.selected) {
                            partnersCampaignsAccepted = YES_KEY;
                        }

                        break;
                    case 'own':
                        ownCampaigns = true;

                        if (campaign.selected) {
                            ownCampaignsAccepted = YES_KEY;
                        }

                        break;
                    default:
                        console.warn('Campaign Content Type unknown: ', campaign.consent.contentType);

                        break;
                    }

                    //modal is hidden
                } else {
                    const key = campaign.consent.externalId;

                    if (typeof adcQuestions[key] !== 'object') {
                        adcQuestions[key] = {campaigns : [], required : false};
                    }

                    if (campaign.consent.required) {
                        adcQuestions[key].required = true;
                        adcFirstQuestion           = campaign.consent.text;
                    }

                    adcQuestions[key].text = campaign.consent.text;
                    adcQuestions[key].campaigns.push(campaign.id);
                }
            });

            //modal is hidden
            if (hidden) {
                App.Chat.addVariable(ADC_CONSENT_QUESTIONS_KEY, adcQuestions, ADC_CONSENT_QUESTIONS_EXTRA_KEY);
                App.Chat.addVariable(ADC_CONSENT_QUESTION_KEY, adcFirstQuestion);
                App.Chat.addVariable(ADC_CONSENT_ANSWER_KEY, 'no');
                App.Chat.addVariable(ADC_CONSENT_QUESTION_NUMBER_KEY, 1);
            }

            //TO BE DEPRECATED
            if (partnersCampaigns) {
                App.Chat.addVariable(TERMS_PARTNERS_CHAT_VARIABLE, partnersCampaignsAccepted, TERMS_PARTNERS_CHAT_VARIABLE_EXTRA);
            }

            //TO BE DEPRECATED
            if (ownCampaigns) {
                App.Chat.addVariable(TERMS_NEWSLETTER_CHAT_VARIABLE, ownCampaignsAccepted, TERMS_NEWSLETTER_CHAT_VARIABLE_EXTRA);
                App.Chat.addVariable(TERMS_OWN_CHAT_VARIABLE, ownCampaignsAccepted, TERMS_OWN_CHAT_VARIABLE_EXTRA);
            }
        },

        accept(event) {
            if (this.submitLead) {
                event.preventDefault();
            }

            this.setCampaignsInSmarkioVariables(false);

            App.Chat.addVariable(TERMS_ACCEPTED_CHAT_VARIABLE, YES_KEY, TERMS_ACCEPTED_CHAT_VARIABLE);
            App.Chat.addVariable(USER_ACCEPTED_CHAT_VARIABLE, YES_KEY, USER_ACCEPTED_CHAT_VARIABLE);

            let element = document.querySelector(`.${this.buttonClass}`);

            if (element !== null) {
                element.hidden = true;
            }

            setTimeout(() => {
                this.$destroy();
            }, 1500);

            this.$emit('closeModal', true);
        },

        reject() {

            this.setCampaignsInSmarkioVariables(false);

            App.Chat.addVariable(TERMS_ACCEPTED_CHAT_VARIABLE, NO_KEY, TERMS_ACCEPTED_CHAT_VARIABLE);
            App.Chat.addVariable(USER_ACCEPTED_CHAT_VARIABLE, NO_KEY, USER_ACCEPTED_CHAT_VARIABLE);
            App.Chat.addVariable(TERMS_NEWSLETTER_CHAT_VARIABLE, NO_KEY, TERMS_NEWSLETTER_CHAT_VARIABLE_EXTRA);
            App.Chat.addVariable(TERMS_OWN_CHAT_VARIABLE, NO_KEY, TERMS_OWN_CHAT_VARIABLE_EXTRA);
            App.Chat.addVariable(TERMS_PARTNERS_CHAT_VARIABLE, NO_KEY, TERMS_PARTNERS_CHAT_VARIABLE_EXTRA);

            setTimeout(() => {
                App.Chat.unsetVariable(TERMS_ACCEPTED_CHAT_VARIABLE);
                App.Chat.unsetVariable(USER_ACCEPTED_CHAT_VARIABLE);
                App.Chat.unsetVariable(TERMS_NEWSLETTER_CHAT_VARIABLE);
                App.Chat.unsetVariable(TERMS_OWN_CHAT_VARIABLE);
                App.Chat.unsetVariable(TERMS_PARTNERS_CHAT_VARIABLE);
            }, 1200);

            this.$emit('closeModal', false);
        }
    }
};

// extend Modal
export default {...Modal, ...smarkio};
