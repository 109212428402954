import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './messages';
import languages from './languages';

Vue.use(VueI18n);

export default (language) => new VueI18n({
    locale: languages.normalize(language),
    messages
});
