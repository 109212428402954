import Fa from 'vue-fa';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


export default {
    template : require('./accordion-item.vue'),
    name: 'accordion-item',
    props: {
        title: String,
        content: String,
    },
    inject: ['Accordion'],
    components : {
        Fa
    },
    data() {
        return {
            index: null,
            faAngleDown
        };
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active;
        }
    },
    methods: {
        open() {
            if (this.visible) {
                this.Accordion.active = null;
            } else {
                this.Accordion.active = this.index;
            }
        },
        start(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        end(el) {
            el.style.height = '';
        }
    },
    created() {
        this.index = this.Accordion.count++;
    }
};